import { MenuTriggerState } from '@react-stately/menu';
import { cn, IconButton } from '@youversion/design-system-components';
import Line3HorizontalStroked from '@youversion/design-system-icons/lib/Line3HorizontalStroked';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { ChurchLogoAndNameDisplay } from '@/components/ChurchLogoAndNameDisplay';
import { useOrganization } from '@/context/organization/useOrganization';

import { NavItems } from './NavItems';

interface Props {
  state: MenuTriggerState;
}

export default function Nav({ state }: Props) {
  const { t } = useTranslation(['common']);

  const { bibleProfile, churchProfile, contentProfile } = useOrganization();

  const insightsHref = useMemo((): string | undefined => {
    if (bibleProfile) {
      return '/insights/bible';
    } else if (churchProfile) {
      return '/insights/church';
    } else if (contentProfile) {
      return '/insights/dashboard';
    } else {
      return '/insights/youversion';
    }
  }, [bibleProfile, churchProfile, contentProfile]);

  return (
    <div>
      <div
        className={cn(
          'bg-canvas-light dark:bg-canvas-secondary-dark border-gray-10 dark:border-gray-40 border-b-small border-solid flex lg:hidden items-center plb-1.5 pli-1',
          state.isOpen ? 'hidden' : ''
        )}
      >
        <IconButton aria-label={t('common:menu')} onClick={() => state.open()}>
          <Line3HorizontalStroked />
        </IconButton>
        <ChurchLogoAndNameDisplay imageBoxClassNames='h-5 w-5 mie-1.5 mis-1' imageSize={40} />
      </div>

      <NavItems insightsHref={insightsHref} state={state} />
    </div>
  );
}
