import { useRadioGroupState } from '@react-stately/radio';
import { Button, Dialog, RadioSet, RadioSetItem } from '@youversion/design-system-components';
import { setCookie } from 'cookies-next';
import { useRouter } from 'next/compat/router';
import { useTranslation } from 'next-i18next';
import { useTheme } from 'next-themes';
import * as React from 'react';

import LanguageSelect from '../LanguageSelect/LanguageSelect';

interface Props {
  onClose: () => void;
}

interface ThemeOption {
  label: string;
  value: string;
}

export default function SettingsDialog({ onClose }: Props) {
  const { t } = useTranslation('common');
  const { theme, setTheme } = useTheme();
  const router = useRouter();
  const radioState = useRadioGroupState({
    onChange: value => {
      setTheme(value);
    },
    value: theme,
  });

  const options: ReadonlyArray<ThemeOption> = [
    {
      label: t('use_browser_setting'),
      value: 'system',
    },
    {
      label: t('always_off'),
      value: 'light',
    },
    {
      label: t('always_on'),
      value: 'dark',
    },
  ] as const;

  function handleLanguageChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setCookie('app_locale', event.currentTarget.value, { path: '/' });
    router?.replace(
      { pathname: router.pathname, query: router.query },
      {},
      {
        locale: event.currentTarget.value,
      }
    );
  }

  return (
    <Dialog heading={t('settings')} onClose={onClose}>
      <div className='flex flex-col gap-4'>
        <LanguageSelect onLanguageChange={handleLanguageChange} type='APP' />
        <RadioSet aria-label={t('low_light_mode')} heading={t('low_light_mode')} role='radiogroup'>
          {options.map(option => (
            <RadioSetItem
              checked={option.value === radioState.selectedValue}
              id={option.value}
              key={option.value}
              name='low_light_mode'
              onChange={event => {
                if (event.currentTarget.checked) {
                  radioState.setSelectedValue(event.currentTarget.value);
                }
              }}
              value={option.value}
            >
              {option.label}
            </RadioSetItem>
          ))}
        </RadioSet>
      </div>
      <Button className='mbs-3' onClick={onClose}>
        {t('done')}
      </Button>
    </Dialog>
  );
}
