import { useCallback, useMemo } from 'react';

import { usePermissions } from '@/components/permissions';
import { AuthPermission, Permission, PermissionState, ProfileType } from '@/utils/constants';

export interface PermissionCheckParams {
  permissions?: Array<Permission>;
  /** Array of organization profiles required for access. */
  profiles?: Array<ProfileType>;
  requiresAllPermissions?: boolean;
  requiresAllProfiles?: boolean;
}

/**
 * Hook to check if `requiredPermission` is in array of `resources`.
 *
 * @remarks Must be used inside of `<PermissionsProvider />`.
 *
 * @param permissions - The permission required to view the component.
 * @param requiresAll - Determines whether page requires all passed permissions to be AUTHORIZED or just a single passed permission, defaults to `false`.
 *
 * @example
 * ```jsx
 * import { useHasPermissions } from '@/components/permissions'
 *
 * const { hasPermissions: hasCreateLocationPermission } = useHasPermissions({
 *   permissions: [
 *     {
 *     permissionKey: 'organizations.church.create_location',
 *     resources: [organization?.fqrn],
 *   },
 *  ],
 *});
 * ```
 * @returns
 * - If the `PermissionProvider` context is loading - `PermissionState.PENDING`
 * - If `requiredPermission` is found in `resources` -  `PermissionState.AUTHORIZED`,
 * - If `requiredPermission` is not found - `PermissionState.UNAUTHORIZED`.
 *
 * @throws Throws error when attempting to use hook when not inside of `PermissionsProvider`.
 */

export default function useHasPermissions({
  permissions: inputPermissions,
  profiles: inputProfiles,
  requiresAllPermissions: inputRequiresAllPermissions,
  requiresAllProfiles: inputRequiresAllProfiles,
}: PermissionCheckParams) {
  const context = usePermissions();

  if (!context) {
    throw new Error('`useHasPermissions` must be used inside of a `PermissionsProvider`.');
  }

  const checkHasPermissions = useCallback(
    ({ permissions, profiles, requiresAllPermissions = false, requiresAllProfiles = false }: PermissionCheckParams) => {
      const { isError, isLoading, userPermissions, organizationProfiles } = context;

      if (isError) {
        return PermissionState.UNAUTHORIZED;
      }

      if (isLoading) {
        return PermissionState.PENDING;
      }

      // Ensure organization has the all required profiles
      if (profiles && !requiresAllProfiles && !profiles.some(profile => organizationProfiles.includes(profile))) {
        return PermissionState.UNAUTHORIZED;
      } else if (profiles && requiresAllProfiles) {
        const profileState: Array<AuthPermission> = [];
        profiles.forEach(profile =>
          organizationProfiles.includes(profile)
            ? profileState.push(PermissionState.AUTHORIZED)
            : profileState.push(PermissionState.UNAUTHORIZED)
        );
        if (profileState.includes(PermissionState.UNAUTHORIZED)) {
          return PermissionState.UNAUTHORIZED;
        }
      }

      // Clear out any empty values
      const filteredPermissions = permissions?.filter(permission => permission.resources !== undefined);

      const permissionResults: Array<AuthPermission> = [];

      if (!filteredPermissions) {
        return PermissionState.UNAUTHORIZED;
      }

      filteredPermissions?.forEach((requestedPermission: Permission) => {
        for (const resourceToCheck of requestedPermission.resources) {
          userPermissions?.find(
            userPermission =>
              userPermission.resource === resourceToCheck &&
              userPermission.permissions.some(
                userPermission =>
                  [requestedPermission.permissionKey, '*'].includes(userPermission) ||
                  (requestedPermission.permissionKey.startsWith('organizations.') &&
                    userPermission === 'organizations.*')
              )
          )
            ? permissionResults.push(PermissionState.AUTHORIZED)
            : permissionResults.push(PermissionState.UNAUTHORIZED);
        }
      });

      if (requiresAllPermissions) {
        return permissionResults.some(permissionResult => permissionResult === PermissionState.UNAUTHORIZED)
          ? PermissionState.UNAUTHORIZED
          : PermissionState.AUTHORIZED;
      } else {
        return permissionResults.some(permissionResult => permissionResult === PermissionState.AUTHORIZED)
          ? PermissionState.AUTHORIZED
          : PermissionState.UNAUTHORIZED;
      }
    },
    [context]
  );

  const hasPermissions = useMemo(
    () =>
      checkHasPermissions({
        permissions: inputPermissions,
        profiles: inputProfiles,
        requiresAllPermissions: inputRequiresAllPermissions,
        requiresAllProfiles: inputRequiresAllProfiles,
      }),
    [checkHasPermissions, inputPermissions, inputProfiles, inputRequiresAllPermissions, inputRequiresAllProfiles]
  );

  return { checkHasPermissions, hasPermissions };
}
