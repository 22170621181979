import { Button, Dialog, Text } from '@youversion/design-system-components';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import * as React from 'react';

import { useOrganization } from '@/context/organization/useOrganization';
import { ImagesOrganizationImage, Status2 } from '@/graphql';
import placeholderImage from '@/public/svg/page-publish-placeholder.svg';
import { verificationProcessLink } from '@/utils/constants';
import { safeString } from '@/utils/strings';

import { StatusActionRibbon } from './StatusActionRibbon';

export type ChurchVerifiedStatus = 'default' | 'pending' | 'rejected' | 'success';

interface Props {
  children?: React.ReactNode;
  churchData: {
    id?: string;
    logo?: ImagesOrganizationImage;
    logoIsUploaded: boolean;
    name?: string;
    schedule?: boolean;
    status?: Status2;
  };
  onClose: () => void;
  onSubmit: () => void;
}
export const PublishYourChurchModal = ({ onSubmit, onClose, children, churchData }: Props) => {
  const { t } = useTranslation(['common']);
  const { hasMultipleLocations } = useOrganization();
  const scheduleHref = hasMultipleLocations
    ? '/profile/locations'
    : `/profile/add-schedule?locationId=${churchData.id}`;

  const status = getChurchVerifiedStatusVariant();
  function getChurchVerifiedStatusVariant(): ChurchVerifiedStatus {
    switch (churchData.status) {
      case Status2.VERIFIED:
      case Status2.PUBLISHED:
      case Status2.HIDDEN:
        return 'success';
      case Status2.REJECTED:
        return 'rejected';
      case Status2.CREATED:
        return 'pending';
      default:
        return 'default';
    }
  }

  const [logoUploadStatus, setLogoUploadStatus] = React.useState<ChurchVerifiedStatus>(
    churchData.logoIsUploaded ? 'success' : 'default'
  );

  const [scheduleStatusVariant] = React.useState<ChurchVerifiedStatus>(() =>
    typeof churchData.schedule !== 'undefined' ? 'success' : 'default'
  );

  const allStepsAreCompleteAndValid = React.useMemo(
    () => Boolean(scheduleStatusVariant === 'success' && logoUploadStatus === 'success' && status === 'success'),
    [scheduleStatusVariant, logoUploadStatus, status]
  );

  return (
    <Dialog
      closeButtonLabel={t('common:dismiss')}
      dialogType='modal'
      heading={t('common:church_profile_publish.modal.headline')}
      isDismissableOnOutsideClick={true}
      onClose={onClose}
    >
      <div className='flex flex-col gap-1.5'>
        <div className='overflow-hidden rounded-22 h-22 w-22'>
          <Image
            alt={t('common:organization_logo', { church_name: safeString(churchData?.name) })}
            height={250}
            onError={event => {
              event.currentTarget.onerror = null;
              setLogoUploadStatus('default');
              event.currentTarget.src = placeholderImage.src;
            }}
            src={churchData.logo?.imageUrl || placeholderImage.src || 'svg/page-publish-placeholder.svg'}
            unoptimized={true}
            width={250}
          />
        </div>
        <Text size='md' variant='fieldTextInput'>
          {t('common:church_profile_publish.modal.intro_description')}
        </Text>
        <StatusActionRibbon clickActionType='info' href={verificationProcessLink} target='_blank' variant={status}>
          <div>
            {t('common:church_profile_publish.modal.church_verified')}
            {status === 'pending' ? (
              <Text size='sm' variant='paragraph'>
                <span className='text-gray-20'>{t('common:church_profile_publish.modal.review_pending')}</span>
              </Text>
            ) : null}
          </div>
        </StatusActionRibbon>
        <StatusActionRibbon clickActionType='nav' href='/profile/edit' variant={logoUploadStatus}>
          {t('common:church_profile_publish.modal.add_logo')}
        </StatusActionRibbon>
        <StatusActionRibbon clickActionType='nav' href={scheduleHref} variant={scheduleStatusVariant}>
          {t('common:church_profile_publish.modal.add_schedule')}
        </StatusActionRibbon>
        {children}
        <div />
        <Button color='gray-50' disabled={!allStepsAreCompleteAndValid} name='preview_publish' onClick={onSubmit}>
          {t('common:church_profile_publish.modal.preview_publish')}
        </Button>
      </div>
    </Dialog>
  );
};
