import { Heading, Text } from '@youversion/design-system-components';
import { Trans, useTranslation } from 'next-i18next';
import * as React from 'react';

import { useSupportUrls } from '@/hooks/use-support-urls';

const Unauthorized = () => {
  const { t } = useTranslation(['common']);

  const { supportUrl } = useSupportUrls();
  return (
    <div className='w-[1200px] p-4 m-auto'>
      <Heading className='text-20 lg:text-25' size='md' variant='headline'>
        {t('common:no_access_to_feature')}
      </Heading>
      <Text className='mbs-1' size='md' variant='fieldTextInput'>
        <Trans
          components={{
            a: <a href={supportUrl} rel='noreferrer' target='_blank' />,
          }}
          i18nKey='contact_support'
        />
      </Text>
    </div>
  );
};

export default Unauthorized;
