import { useOverlayTriggerState } from '@react-stately/overlays';
import { useTranslation } from 'next-i18next';
import * as React from 'react';

import { Metrics, UserAction } from '@/api/metrics';
import TakeActionWell from '@/components/TakeActionWell';
import { useOrganization } from '@/context/organization/useOrganization';
import {
  AdminOrganizationsOrganization,
  AdminOrganizationsOrganizationServiceTime,
  Status2,
  useGetOrganizationServiceTimesQuery,
  usePublishChurchMutation,
} from '@/graphql';
import notEmpty from '@/utils/notEmpty';
import { organizationProfileUrl } from '@/utils/organization-profile-url';
import { safeString } from '@/utils/strings';
import { errorToast } from '@/utils/toast';

import { ChurchProfileCongratulationsModal, PreviewAndPublishChurchModal, PublishYourChurchModal } from '.';

const ProfileStatus = {
  Complete: 'complete',
  Incomplete: 'incomplete',
} as const;

type ServiceTimesDataType = {
  locationServiceTimes: Array<AdminOrganizationsOrganization>;
  serviceTimes: Array<AdminOrganizationsOrganizationServiceTime>;
};

export function PublishChurchProfileStatusActionRibbon() {
  const { t } = useTranslation(['common', 'churchProfile']);
  const { organization, invalidateQueries, hasMultipleLocations, organizationId, churchProfile } = useOrganization();

  const { data: serviceTimeData } = useGetOrganizationServiceTimesQuery(
    { organizationId: safeString(organizationId) },
    {
      enabled: Boolean(organizationId),
      select: data => {
        return {
          locationServiceTimes: data.locationsServiceTimes?.data?.filter(notEmpty),
          serviceTimes: data.getAdminOrganization?.serviceTimes?.data?.filter(notEmpty),
        } as ServiceTimesDataType;
      },
    }
  );

  const publishModal = useOverlayTriggerState({});
  const previewAndPublishModal = useOverlayTriggerState({});
  const publishCongratulationsModal = useOverlayTriggerState({});

  const logoIsUploaded = Boolean(organization?.logo?.imageUrl != null);
  const profileIsVerified = Boolean(
    churchProfile?.status &&
      [Status2.PUBLISHED, Status2.VERIFIED, Status2.HIDDEN].includes(churchProfile?.status as Status2)
  );

  const hasSchedule: boolean = React.useMemo(() => {
    if (hasMultipleLocations) {
      // Some locations must have service times
      return Boolean(serviceTimeData?.locationServiceTimes?.some(church => church?.serviceTimes?.data?.length));
    }
    return Boolean(serviceTimeData?.serviceTimes?.length);
  }, [hasMultipleLocations, serviceTimeData]);

  const completedPublishingSteps = (logoIsUploaded ? 1 : 0) + (profileIsVerified ? 1 : 0) + (hasSchedule ? 1 : 0);
  const profileStatus = completedPublishingSteps >= 3 ? ProfileStatus.Complete : ProfileStatus.Incomplete;
  const profileLinkString = organizationProfileUrl(organization?.id);

  const { mutate } = usePublishChurchMutation({
    onError: (error: Error) => {
      errorToast(t('common:something_went_wrong'));
      if (organization?.id) {
        const jsonErrors = JSON.parse(error.message);
        Metrics.sendUserActionErrorEvent({
          action: UserAction.OrganizationsChurchPublish,
          message: jsonErrors?.[0]?.message,
          object_id: organization.id,
        });
      }
    },
    onSuccess: () => {
      if (organization?.id) {
        Metrics.sendUserActionSucceedEvent({
          action: UserAction.OrganizationsChurchPublish,
          object_id: organization.id,
        });
      }
      invalidateQueries();
      publishCongratulationsModal.open();
      previewAndPublishModal.close();
    },
  });

  return (
    <>
      {publishModal.isOpen ? (
        <PublishYourChurchModal
          churchData={{
            id: organization?.id || undefined,
            logo: organization?.logo || undefined,
            logoIsUploaded: logoIsUploaded,
            name: organization?.name || undefined,
            schedule: hasSchedule || undefined,
            status: churchProfile?.status as Status2,
          }}
          onClose={publishModal.close}
          onSubmit={() => {
            previewAndPublishModal.open();
            publishModal.close();
          }}
        />
      ) : null}
      {previewAndPublishModal.isOpen ? (
        <PreviewAndPublishChurchModal
          onClose={previewAndPublishModal.close}
          onPublish={() => {
            if (organization?.id) {
              Metrics.sendUserActionStartEvent({
                action: UserAction.OrganizationsChurchPublish,
                object_id: organization.id,
              });
              mutate({ organizationId: organization.id });
            }
          }}
          profileLink={profileLinkString}
        />
      ) : null}
      {publishCongratulationsModal.isOpen ? (
        <ChurchProfileCongratulationsModal
          onClose={publishCongratulationsModal.close}
          profileLink={profileLinkString}
        />
      ) : null}
      {churchProfile?.status &&
      [Status2.REJECTED, Status2.CREATED, Status2.VERIFIED].includes(churchProfile?.status as Status2) ? (
        <TakeActionWell
          ariaLabel={t('common:church_profile_publish.publishing_steps')}
          background={profileStatus === ProfileStatus.Complete ? 'success' : 'info'}
          description={t('common:church_profile_publish.steps_complete', {
            count: `${completedPublishingSteps}/3`,
          })}
          onClick={() => {
            publishModal.open();
          }}
          title={t('common:church_profile_publish.publish_profile')}
        />
      ) : null}
    </>
  );
}
