import * as React from 'react';

import { useHasPermissions } from '@/components/permissions';
import { PermissionState } from '@/utils/constants';

type Props = Parameters<typeof useHasPermissions>[0] & {
  children: React.ReactNode;
};
/**
 * Wrapper that will disable children components when the user does not have the required permissions.
 *
 * @param permissions - Permissions to check with useHasPermissions hook.
 * @param profiles - Array of organization profiles required for access.
 * @param requiresAll - Determines whether component requires all passed permissions to be AUTHORIZED or just a single passed permission, defaults to `false`.
 *
 * @remarks Must be used inside of `<PermissionsProvider />`.
 *
 * @example
 * ```jsx
 * import { PermissionGuard } from '@/components/permissions'
 *
 * <PermissionGuard
 *   permissions={[
 *     {
 *       permissionKey: 'organizations.church.create_location',
 *       resources: [organization?.fqrn],
 *     },
 *   ]}
 * >
 *   <Text>This is example text</Text>
 * </PermissionGuard>
 * ```
 * @returns
 * - If `permissionKey` is found inside `resources` - Return `children` inside of a fragment,
 * - Otherwise it will return an empty fragment.
 */
export default function PermissionGuard({ children, permissions, profiles, requiresAllPermissions }: Props) {
  const { hasPermissions } = useHasPermissions({ permissions, profiles, requiresAllPermissions });

  return <>{hasPermissions === PermissionState.AUTHORIZED ? children : null}</>;
}
