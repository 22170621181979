import { useAuth } from '@youversion/auth';
import { useMemo } from 'react';

import { FeatureFlagType } from '@/components/FeatureFlag/FeatureFlag';
import { useOrganization } from '@/context/organization/useOrganization';
import { GetFeatureFlagsQuery, useGetFeatureFlagQuery, useGetFeatureFlagsQuery } from '@/graphql';

const NOT_FOUND_STATUS_CODE = /statusCode":\s*(404)/;

export function useGetFeatureFlags({ featureFlag }: { featureFlag: FeatureFlagType }) {
  const { organization, isLoading: isLoadingOrganization } = useOrganization();
  const { user, isLoading: isLoadingUser } = useAuth();

  // Check if the feature flags exists. If 404, then all users should have access to the feature.
  const { data: featureFlagData, error: featureFlagError } = useGetFeatureFlagQuery(
    { featureFlagId: featureFlag },
    {
      retry: (failureCount, error) => {
        if (error instanceof Error) {
          const error404StatusMatch = NOT_FOUND_STATUS_CODE.exec(error.message);

          if (error404StatusMatch) {
            return false;
          }
        }
        return failureCount < 3;
      },
      select: response => response.getAdminFeatureFlag,
    }
  );

  // Get feature flags for the organization.
  const { data: organizationFeatureFlags, isLoading: isLoadingOrgFeaturesQuery } = useGetFeatureFlagsQuery(
    { principalId: organization?.id ? `organization:${organization.id}` : '' },
    {
      enabled: Boolean(organization?.id),
      select: (response: GetFeatureFlagsQuery) => response.getAdminPrincipalFeatureFlags?.data,
    }
  );

  const isLoadingOrgFeatures = isLoadingOrgFeaturesQuery && Boolean(organization?.id);

  // Get feature flags for the user.
  const { data: userFeatureFlags, isLoading: isLoadingUserFeaturesQuery } = useGetFeatureFlagsQuery(
    { principalId: user?.id ? `user:${user.id}` : '' },
    {
      enabled: Boolean(user?.id),
      select: (response: GetFeatureFlagsQuery) => response.getAdminPrincipalFeatureFlags?.data,
    }
  );

  const hasFeature = useMemo(
    () =>
      (organizationFeatureFlags?.some(feature => feature?.id === featureFlag) ||
        userFeatureFlags?.some(feature => feature?.id === featureFlag)) ??
      false,

    [featureFlag, organizationFeatureFlags, userFeatureFlags]
  );

  // Early return if feature flag is inactive.
  if (featureFlagData?.status === 'INACTIVE') {
    return { hasFeature: true, isLoading: false };
  }

  // Early return if feature flag is not found.
  if (featureFlagError instanceof Error) {
    const statusCodeMatch = NOT_FOUND_STATUS_CODE.exec(featureFlagError.message);
    if (statusCodeMatch) {
      return { hasFeature: true, isLoading: false };
    }
  }

  const isLoadingUserFeatures = isLoadingUserFeaturesQuery && Boolean(user?.id);
  const isLoading = isLoadingOrgFeatures || isLoadingUserFeatures || isLoadingOrganization || isLoadingUser;

  return { hasFeature, isLoading };
}
