import * as React from 'react';

import { useGetFeatureFlags } from '@/hooks/useGetFeatureFlags';

/** ...................
 * A wrapper to conditionally render components with a feature flag check.
 *
 * For conditionally rendering pages, see `FeatureFlagPageLayout`.
 *
 * @example
 * ```tsx
 * import FeatureFlag from '@/components';
 *
 * function MyComponent() {
 *   return (
 *     <FeatureFlag featureFlag='custom_collections' >
 *       <SomeComponent />
 *     </FeatureFlag>
 *   )
 * }
 * ```
 */

export type FeatureFlagType =
  | 'email_management'
  | 'experimental_insights_page'
  | 'celebration_image'
  | 'church_insights_release'
  | 'pending_account_invites'
  | 'plan_insights_release'
  | 'trends_release'
  | 'language_specific_page_info'
  | 'scheduled_posts';

export type PrincipalType = 'organization' | 'user';

interface Props {
  children: React.ReactNode;
  fallbackPath?: string;
  featureFlag: FeatureFlagType;
  principalType?: PrincipalType;
}
function FeatureFlag({ children, featureFlag }: Props) {
  const { hasFeature, isLoading } = useGetFeatureFlags({ featureFlag });

  if (hasFeature && !isLoading) {
    return <>{children}</>;
  }

  return null;
}

export default FeatureFlag;
