import { Option, Select } from '@youversion/design-system-components';
import { useTranslation } from 'next-i18next';
import * as React from 'react';

import { useAppLanguage } from '@/context/appLanguage';
import { useLocales } from '@/hooks/locales';
import { SelectOption } from '@/utils/types';

interface Props {
  className?: string;
  onLanguageChange: React.ChangeEventHandler<HTMLSelectElement>;
  type?: 'APP' | 'API';
  value?: string;
}
const LanguageSelect = ({ className, onLanguageChange, type = 'APP', value }: Props) => {
  const { t } = useTranslation();
  const { appLanguage: defaultAppLanguage } = useAppLanguage();
  const { allLanguages, enabledLanguages } = useLocales();

  const resolvedValue = value || defaultAppLanguage;
  const resolvedLanguages = type === 'APP' ? enabledLanguages : allLanguages;

  const selectOptions: Array<SelectOption> = resolvedLanguages.map(localeItem => {
    let resolvedLabel = localeItem.localizedLanguageLabel;
    if (type === 'APP' && localeItem.displayName && localeItem.locale?.includes(resolvedValue!)) {
      resolvedLabel = localeItem.displayName;
    }
    return {
      label: resolvedLabel,
      value: type === 'APP' ? localeItem.locale : localeItem.apiTag,
    };
  });

  return (
    <Select
      aria-label={t('language')}
      className={className}
      name={t('language')}
      onChange={onLanguageChange}
      placeholder={t('language')}
      value={resolvedValue}
    >
      {selectOptions.map(option => (
        <Option disabled={option.disabled} key={option.value} selected={option.selected} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default LanguageSelect;
