import { Button, Dialog, Text } from '@youversion/design-system-components';
import { useTranslation } from 'next-i18next';
import * as React from 'react';
import { QRCode } from 'react-qrcode-logo';

interface Props {
  children?: React.ReactNode;
  onClose: () => void;
  onPublish: () => void;
  profileLink: string;
}
export const PreviewAndPublishChurchModal = ({ children, onClose, onPublish, profileLink }: Props) => {
  const { t } = useTranslation(['churchProfile']);

  return (
    <Dialog className='pbs-6' dialogType='modal' onClose={onClose}>
      <div className='w-full pbe-3'>
        <div className='flex flex-col gap-1.5'>
          <Text className='text-20 lg:text-25' size='md' variant='headline'>
            {t('churchProfile:church_profile_publish.modal.preview_publish')}
          </Text>
          <div className='flex flex-col items-center border-small border-solid border-tertiary-light dark:border-tertiary-dark gap-3 p-1.5 rounded-1'>
            <Text>{t('churchProfile:church_profile_publish.modal.preview')}</Text>
            <div aria-label={t('churchProfile:church_profile_publish.modal.preview_qr')}>
              <QRCode eyeRadius={{ inner: 1, outer: 6 }} qrStyle='dots' quietZone={8} size={160} value={profileLink} />
            </div>
            <Text>
              <a href={profileLink} rel='noreferrer' target='_blank'>
                {profileLink}
              </a>
            </Text>
          </div>
          {children}
          <div />
          <Button color='gray-50' onClick={onPublish}>
            {t('churchProfile:church_profile_publish.modal.publish')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
