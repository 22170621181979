import { LoadingSpinner } from '@youversion/design-system-components';
import { useTranslation } from 'next-i18next';
import * as React from 'react';

import { Unauthorized, useHasPermissions } from '@/components/permissions';
import { PermissionState } from '@/utils/constants';

type Props = Parameters<typeof useHasPermissions>[0] & {
  children: React.ReactNode;
};

/**
 * Wrapper to handle displaying of entire page based on permissions.
 *
 * @remarks Must be used inside of `<PermissionsProvider />`.
 *
 * @param permissions - Permissions to check with useHasPermission hook.
 * @param profiles - Array of organization profiles required for access.
 * @param requiresAll - Determines whether page requires all passed permissions to be AUTHORIZED or just a single passed permission, defaults to `true`.
 *
 * @example
 * ```jsx
 * import { PageGuard } from '@/components/permissions'
 *
 * <PageGuard permissions={[
 *     {
 *       permissionKey: 'organizations.church.create_location',
 *       resources: [organization?.fqrn],
 *     },
 * ]}>
 *   <Page>This is an example page</Page>
 * </PageGuard>
 * ```
 * @returns
 * - If `PermissionState.PENDING` - Return `<LoadingSpinner />`,
 * - If `PermissionState.UNAUTHORIZED` - Return `<Unauthorized />`.
 * - If `PermissionState.AUTHORIZED` - Return `<>{children}</>`.
 */
export default function PageGuard({ children, permissions, profiles, requiresAllPermissions }: Props) {
  const { hasPermissions: permissionResult } = useHasPermissions({ permissions, profiles, requiresAllPermissions });
  const { t } = useTranslation();

  switch (permissionResult) {
    case PermissionState.PENDING:
      return <LoadingSpinner aria-label={t('loading')} />;
    case PermissionState.UNAUTHORIZED:
      return <Unauthorized />;
    case PermissionState.AUTHORIZED:
      return <>{children}</>;
  }
}
