import { useOrganization } from '@/context/organization/useOrganization';

/**
 * Since FormStack uses custom field IDs, this object puts names to field IDs for better readability.
 */
export const fields = {
  feedbackForm: {
    organizationId: 'field133032606',
    organizationPrimaryLanguage: 'field133032607',
  },
  supportForm: {
    browser: 'field150800863',
    currentUrl: 'field150800864',
    organizationId: 'field150800865',
    organizationName: 'field150800859',
    organizationPrimaryLanguage: 'field150800860',
  },
} as const;

interface SupportUrls {
  feedbackUrl: string;
  supportUrl: string;
}

/**
 * Generates links to support forms with pre-filled data.
 *
 * @example
 * ```tsx
 * import { useSupportUrls } from '@/hooks/use-upport-urls';
 *
 * function MyComponent() {
 *   const { feedbackUrl, supportUrl } = useSupportUrls();
 *
 *   return (
 *     <>
 *       <a href={feedbackUrl}>Give Feedback</a>
 *       <a href={supportUrl}>Contact Support</a>
 *     </>
 *   )
 * }
 * ```
 */
export function useSupportUrls(): SupportUrls {
  const supportUrl = new URL('https://lifechurch.formstack.com/forms/yvconnect_support');
  const feedbackUrl = new URL('https://lifechurch.formstack.com/forms/partner_portal_feedback');

  const { organization } = useOrganization();

  supportUrl.searchParams.append(fields.supportForm.currentUrl, window.location.href);

  if (window?.navigator?.userAgent) {
    supportUrl.searchParams.append(fields.supportForm.browser, window.navigator.userAgent);
  }

  if (organization?.id) {
    supportUrl.searchParams.append(fields.supportForm.organizationId, organization.id);
    feedbackUrl.searchParams.append(fields.feedbackForm.organizationId, organization.id);
  }

  if (organization?.name) {
    supportUrl.searchParams.append(fields.supportForm.organizationName, organization.name);
  }

  if (organization?.primaryLanguage) {
    supportUrl.searchParams.append(fields.supportForm.organizationPrimaryLanguage, organization.primaryLanguage);
    feedbackUrl.searchParams.append(fields.feedbackForm.organizationPrimaryLanguage, organization.primaryLanguage);
  }

  return { feedbackUrl: feedbackUrl.href, supportUrl: supportUrl.href };
}
