import { useMenuTriggerState } from '@react-stately/menu';
import {
  cn,
  List,
  ListItem,
  ListItemActions,
  ListItemContent,
  Popover,
  PopoverBody,
  PopoverTrigger,
  Text,
} from '@youversion/design-system-components';
import CheckmarkStroked from '@youversion/design-system-icons/lib/CheckmarkStroked';
import ImageSingle from '@youversion/design-system-icons/lib/ImageSingle';
import TriangleDownStroked from '@youversion/design-system-icons/lib/TriangleDownStroked';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { useOrganizationsForUser } from '@/context/organization';
import { useCurrentOrganizationId } from '@/context/organization/useCurrentOrganizationId';
import { useOrganization } from '@/context/organization/useOrganization';
import { safeString } from '@/utils/strings';

export function OrganizationPopover() {
  const { t } = useTranslation();
  const state = useMenuTriggerState({});
  const { organization: currentOrganization } = useOrganization();
  const { selectOrganization } = useCurrentOrganizationId();
  const { organizations } = useOrganizationsForUser();
  const hasMultipleOrganizations = (organizations?.length || 0) > 1;

  function handleSelect(id: string) {
    state.close();
    selectOrganization({ id, pushTo: '/profile/information' });
  }

  return (
    <Popover className='w-auto whitespace-normal'>
      <PopoverTrigger
        className={cn('flex gap-1 items-center w-full rounded-1 text-start p-1', {
          'hover:bg-gray-10 dark:hover:bg-gray-45': hasMultipleOrganizations,
        })}
        disabled={!hasMultipleOrganizations}
        onToggle={state.toggle}
      >
        <button aria-label={t('select_partner_account')}>
          <div className='flex flex-row items-center justify-center gap-[12px]'>
            <div className='overflow-hidden rounded-full flex-shrink-0 h-4 w-4'>
              {currentOrganization?.logo?.imageUrl ? (
                <Image
                  alt={t('organization_logo')}
                  className='h-full w-full'
                  height={32}
                  src={currentOrganization.logo.imageUrl}
                  width={32}
                />
              ) : (
                <ImageSingle />
              )}
            </div>
            <Text className='leading-default overflow-hidden select-none flex-grow' size='sm' variant='label'>
              {currentOrganization?.name}
            </Text>
          </div>

          {hasMultipleOrganizations ? (
            <div className='flex text-text-muted-light dark:text-text-muted-dark'>
              <TriangleDownStroked />
            </div>
          ) : null}
        </button>
      </PopoverTrigger>
      {state.isOpen ? (
        <PopoverBody
          autoFocus={false}
          className='max-w-[200px] z-10 dark:bg-gray-35 min-h-0'
          isDismissible={true}
          isOpen={true}
          onClose={state.close}
        >
          <List>
            {organizations?.map(organization => (
              <ListItem key={organization.id} onClick={() => handleSelect(safeString(organization.id))}>
                <ListItemActions>
                  <CheckmarkStroked className={cn({ 'opacity-0': currentOrganization?.id !== organization.id })} />
                </ListItemActions>

                <ListItemContent>
                  <Text>{organization.name}</Text>
                </ListItemContent>
              </ListItem>
            ))}
          </List>
        </PopoverBody>
      ) : null}
    </Popover>
  );
}

export default OrganizationPopover;
