import { useMemo } from 'react';

import { useOrganization } from '@/context/organization/useOrganization';
import { useGetAdminOrganizationContentGrantsQuery } from '@/graphql';
import dayjs from '@/lib/dayjs';
import { safeString } from '@/utils/strings';

import { useGetFeatureFlags } from '../useGetFeatureFlags';

const CELEBRATION_PERIOD = {
  endDate: new Date('2025-03-13'),
  startDate: new Date('2025-01-13'),
};

export function useCelebrationStatus() {
  const { organizationId, contentProfile } = useOrganization();
  const { isLoading: isLoadingFeatureFlags, hasFeature } = useGetFeatureFlags({ featureFlag: 'celebration_image' });
  const { isLoading: isLoadingContentGrants, data: contentGrants } = useGetAdminOrganizationContentGrantsQuery(
    {
      organizationId: safeString(organizationId),
      page: 1,
      pageSize: 1,
    },
    { enabled: Boolean(organizationId) }
  );

  const hasAcceptedLicense = contentProfile?.licenseStatus === 'accepted';

  const hasContentGrants = Boolean(
    contentGrants?.getAdminOrganizationContentGrants?.data &&
      contentGrants.getAdminOrganizationContentGrants.data.length > 0
  );

  const wasCreatedBeforeNewYear = dayjs(contentProfile?.createdDt) < dayjs('2025-01-01');

  const isInCelebrationPeriod = useMemo(() => {
    const now = new Date();
    return now >= CELEBRATION_PERIOD.startDate && now < CELEBRATION_PERIOD.endDate;
  }, []);

  return {
    isActive:
      hasFeature || (hasAcceptedLicense && hasContentGrants && isInCelebrationPeriod && wasCreatedBeforeNewYear),
    isLoading: isLoadingFeatureFlags || isLoadingContentGrants,
  };
}
