import { cn } from '@youversion/design-system-components';
import CheckmarkCircleStroked from '@youversion/design-system-icons/lib/CheckmarkCircleStroked';
import ChevronRightStroked from '@youversion/design-system-icons/lib/ChevronRightStroked';
import InfoCircle from '@youversion/design-system-icons/lib/InfoCircle';
import XCircleStroked from '@youversion/design-system-icons/lib/XCircleStroked';
import Link from 'next/link';
import * as React from 'react';
import { ReactElement } from 'react';

import { EmptyCircleIcon } from '@/components/EmptyCircleIcon';

import { ChurchVerifiedStatus } from '../PublishYourChurchModal';

interface StyleSettings {
  backgroundColor: string;
  borderColor: string;
  containerTextColor: string;
  contentTextColor: string;
  mainIcon?: ReactElement;
  secondaryIconColor: { darkMode: string; lightMode: string };
}

function getStyleFromStringOption(option: ChurchVerifiedStatus): StyleSettings {
  switch (option) {
    case 'rejected':
      return {
        /* eslint-disable sort-keys */
        backgroundColor: 'bg-orange-10 dark:bg-orange-50',
        borderColor: 'border-alert',
        containerTextColor: 'alert',
        contentTextColor: 'text-orange-40 dark:text-orange-10',
        mainIcon: <XCircleStroked className='p-0.25 text-alert' height={40} width={40} />,
        secondaryIconColor: { lightMode: 'orange-20', darkMode: 'orange-40' },
      };
    case 'success':
      return {
        backgroundColor: 'bg-green-10 dark:bg-green-50',
        borderColor: 'border-success',
        containerTextColor: 'success',
        contentTextColor: 'text-green-40 dark:text-green-10',
        mainIcon: <CheckmarkCircleStroked className='p-1 text-success' height={40} width={40} />,
        secondaryIconColor: { lightMode: 'green-20', darkMode: 'green-40' },
      };
    case 'pending':
      return {
        backgroundColor: 'bg-transparent',
        borderColor: 'border-gray-10 dark:border-tertiary-dark',
        containerTextColor: 'gray-20 dark:gray-40',
        contentTextColor: 'text-text-muted-light dark:text-text-muted-dark',
        mainIcon: (
          <CheckmarkCircleStroked className='p-1 text-gray-10 dark:text-tertiary-dark' height={40} width={40} />
        ),
        secondaryIconColor: { lightMode: 'gray-20', darkMode: 'gray-40' },
      };
    default:
      return {
        backgroundColor: 'bg-transparent',
        borderColor: 'border-gray-10 border-tertiary-dark',
        containerTextColor: 'text-light dark:text-dark',
        contentTextColor: 'text-gray-40 dark:text-gray-10',
        secondaryIconColor: { lightMode: 'gray-20', darkMode: 'gray-40' },
      };
  }
}

/**
 * A styled ribbon with the ability to display a colored icon (left aligned) to signify various status's, a small icon
 * for its action type (info or nav if clickable), and display its children, centered.
 *
 * This component can take an OnClick function, or href instead if navigation is required.
 */
interface Props {
  children: React.ReactNode;
  clickActionType?: 'info' | 'nav';
  href?: string;
  onClick?: () => void;
  target?: React.HTMLAttributeAnchorTarget;
  variant: ChurchVerifiedStatus;
}
export const StatusActionRibbon: React.FC<Props> = ({
  children,
  href,
  target,
  onClick = undefined,
  clickActionType = 'nav',
  variant = 'default',
}) => {
  const styleSettings = getStyleFromStringOption(variant);

  const secondaryIcon =
    clickActionType === 'info' ? (
      <InfoCircle className='opacity-70' color={styleSettings.secondaryIconColor.darkMode} />
    ) : (
      <ChevronRightStroked className='opacity-70' color={styleSettings.secondaryIconColor.darkMode} />
    );

  const ComponentBody = () => {
    return (
      <div
        className={cn(
          'flex items-center gap-1 sm:p-1 border-small border-solid rounded-1 cursor-pointer',
          styleSettings.backgroundColor,
          styleSettings.borderColor,
          styleSettings.containerTextColor
        )}
        onClick={onClick}
      >
        {styleSettings.mainIcon ?? <EmptyCircleIcon />}
        <div className={cn('flex-1', styleSettings.contentTextColor)}>{children}</div>
        {onClick || href ? secondaryIcon : null}
      </div>
    );
  };

  return href ? (
    <Link href={href} target={target}>
      <ComponentBody />
    </Link>
  ) : (
    <ComponentBody />
  );
};
