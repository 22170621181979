import { Banner, Dialog, Text } from '@youversion/design-system-components';
import { Trans, useTranslation } from 'next-i18next';
import * as React from 'react';

function ChurchShareLink({ href }: { href: string }) {
  return (
    <a href={href} rel='noreferrer' target='_blank'>
      {href}
    </a>
  );
}

interface Props {
  onClose: () => void;
  profileLink: string;
}
export const ChurchProfileCongratulationsModal = ({ profileLink, onClose }: Props) => {
  const { t } = useTranslation(['common', 'churchProfile']);

  return (
    <Dialog
      autoFocus={false}
      className='break-words'
      confirmationButtons={[
        {
          'aria-label': t('common:dismiss'),
          label: t('common:dismiss'),
          onClick: () => onClose(),
        },
      ]}
      dialogType='confirmation'
      heading={t('churchProfile:church_profile_publish.modal.congratulations')}
      isDismissableOnOutsideClick={true}
      onClose={onClose}
    >
      <div className='plb-2'>
        <Banner variant='success'>{t('churchProfile:church_profile_publish.modal.published')}</Banner>
      </div>
      <Text size='md' variant='fieldTextInput'>
        <Trans
          components={{ link: <ChurchShareLink href={profileLink} /> }}
          i18nKey='churchProfile:church_profile_publish.modal.congrats_description'
          t={t}
        />
      </Text>
    </Dialog>
  );
};
