import { cn, Text } from '@youversion/design-system-components';
import ImageSingle from '@youversion/design-system-icons/lib/ImageSingle';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import * as React from 'react';

import { useOrganization } from '@/context/organization/useOrganization';
import { AdminOrganizationsOrganization } from '@/graphql';

// Should be removed when DSv2 will be updated
export type TextVariant =
  | 'headline'
  | 'label'
  | 'paragraph'
  | 'scripture'
  | 'eyebrow'
  | 'caption'
  | 'buttonLabel'
  | 'fieldLabel'
  | 'fieldTextInput';

interface Props {
  imageBoxClassNames?: string;
  imageSize?: number;
  organization?: AdminOrganizationsOrganization;
  outerBoxClassNames?: string;
  textClassName?: string;
  textVariant?: TextVariant;
}
/**
 * Small div that displays a circle image of the current church's logo, as well as the church name.
 *
 * @example
 * ```
 * (<ChurchLogoAndNameDisplay textClassName='text-30 lg:text-40 font-[800]' textVariant='headline' imageSize={40} />)
 *
 * // The component also takes BoxProps, this is the styling of the container holding the image and text
 * (<ChurchLogoAndNameDisplay height='2xl' width='full' />)
 * ```
 */
export function ChurchLogoAndNameDisplay({
  organization,
  textClassName,
  textVariant = 'buttonLabel',
  imageSize = 32,
  outerBoxClassNames,
  imageBoxClassNames,
}: Props) {
  const { t } = useTranslation(['common']);
  const { organization: currentUsersOrganization } = useOrganization();
  const resolvedOrganization = organization || currentUsersOrganization;

  return (
    <div className={cn('flex items-center justify-center gap-1.5', outerBoxClassNames)}>
      <div
        className={cn(
          'flex justify-center items-center h-4 w-4 rounded-4 shrink-0 text-tertiary-light dark:text-tertiary-dark bg-gray-10 dark:bg-gray-45 overflow-hidden shadow-[0_0_0_1px_rgba(0,0,0,0.10)] dark:shadow-[0_0_0_1px_rgba(255,255,255,0.10)]',
          imageBoxClassNames
        )}
      >
        {resolvedOrganization?.logo?.imageUrl ? (
          <Image
            alt={t('common:organization_logo')}
            className='h-full w-full object-fit'
            height={imageSize}
            src={resolvedOrganization.logo.imageUrl}
            width={imageSize}
          />
        ) : (
          <ImageSingle width={20} />
        )}
      </div>
      <Text
        className={cn('select-none overflow-hidden text-light dark:text-dark grow', textClassName)}
        size='md'
        variant={textVariant}
      >
        {resolvedOrganization?.name}
      </Text>
    </div>
  );
}
