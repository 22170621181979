import { Text } from '@youversion/design-system-components';
import PersonFilled from '@youversion/design-system-icons/lib/PersonFilled';

interface FallbackAvatarProps {
  name: string;
}

function FallbackAvatar({ name }: FallbackAvatarProps) {
  const initial = name.charAt(0).toUpperCase();

  return (
    <div className='grid h-full place-content-center border-standard border-solid border-text-light dark:border-text-dark rounded-8 text-text-light dark:text-text-dark '>
      {initial ? (
        <Text className='font-bold select-none text-13'>{initial}</Text>
      ) : (
        // The FallbackAvatar is used in the AccountMenuButton which already has
        // an aria-label so we don't need to add one here; instead a testid is
        // used for testing.
        <PersonFilled data-testid='me-icon' height={20} name='Me' width={20} />
      )}
    </div>
  );
}

export default FallbackAvatar;
