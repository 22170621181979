import { IS_STAGING } from '@/utils/env';
import { safeString } from '@/utils/strings';

/**
 * Creates a fully formed url for the organization profile link.
 *
 * @param id - The organization id.
 *
 * @returns The organization profile url.
 *
 * @example
 * ```typescript
 * // IS_STAGING = false
 * const churchLink = organizationProfileUrl('12345')
 * // outputs `https://www.bible.com/organizations/12345`
 * ```
 */
export function organizationProfileUrl(id?: string | null): string {
  return `https://${IS_STAGING ? 'staging' : 'www'}.bible.com/organizations/${safeString(id)}`;
}
