import Link from 'next/link';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useHasPermissions } from '@/components/permissions';
import TakeActionWell from '@/components/TakeActionWell';
import { useOrganization } from '@/context/organization/useOrganization';

const TOTAL_STEPS = 2;
const COMPLETE = 1;
const INCOMPLETE = 0;

export function ConfirmOrganizationProfileStatusRibbon() {
  const { t } = useTranslation();

  const { contentProfile, addressProfile, organization } = useOrganization();

  const licenseStatusStepStatus = contentProfile?.licenseStatus === 'accepted' ? COMPLETE : INCOMPLETE;

  const organizationDetailsStepStatus = useMemo(() => {
    if (
      addressProfile &&
      contentProfile &&
      organization &&
      contentProfile.primaryContactName &&
      contentProfile.primaryContactEmail
    ) {
      return COMPLETE;
    }
    return INCOMPLETE;
  }, [addressProfile, contentProfile, organization]);

  const { hasPermissions: hasContentUpdatePermission } = useHasPermissions({
    permissions: [
      {
        permissionKey: 'organizations.profiles.content.update',
        resources: [organization?.fqrn],
      },
    ],
  });

  const { hasPermissions: hasLicenseAgreementPermission } = useHasPermissions({
    permissions: [
      {
        permissionKey: 'organizations.sign_content_license_agreement',
        resources: [organization?.fqrn],
      },
    ],
  });

  const stepsComplete = licenseStatusStepStatus + organizationDetailsStepStatus;
  const description = t('content_profile_banner.onboard_steps.complete', {
    count: TOTAL_STEPS,
    steps_complete: stepsComplete,
  });
  const shouldRender = stepsComplete < TOTAL_STEPS;

  if (stepsComplete === 0 && shouldRender && hasContentUpdatePermission === 'AUTHORIZED') {
    return (
      <Link href='/register/content-partner' passHref={true}>
        <TakeActionWell
          ariaLabel={t('content_profile_banner.confirm_your_profile')}
          background='caution'
          description={description}
          onClick={() => {}}
          title={t('content_profile_banner.confirm_your_profile')}
        />
      </Link>
    );
  } else if (stepsComplete === 1 && shouldRender && hasLicenseAgreementPermission === 'AUTHORIZED') {
    return (
      <Link href='/register/license-agreement' passHref={true}>
        <TakeActionWell
          ariaLabel={t('content_profile_banner.confirm_your_profile')}
          background='caution'
          description={description}
          onClick={() => {}}
          title={t('content_profile_banner.confirm_your_profile')}
        />
      </Link>
    );
  } else {
    return null;
  }
}
