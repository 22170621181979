import { useMenuTriggerState } from '@react-stately/menu';
import { cn } from '@youversion/design-system-components';
import * as React from 'react';

import Nav from '@/components/Nav';
import { useOrganization } from '@/context/organization/useOrganization';

import { ConfirmOrganizationProfileStatusRibbon, PublishChurchProfileStatusActionRibbon } from '.';

export enum PageType {
  // Pages Reached From Nav. Root Pages.
  Root,
  // Edit Pages, Add Pages, etc.
  SubPage,
  // SubPage with scrolling disabled
  SubPageNoScroll,
  // Drag and Drop Subpage
  DnDSubPage,
  // Register
  Register,
  // New Page Layout
  BaseNextPage,
}

const pageWrapperClassName = 'flex justify-center w-full lg:h-screen overflow-y-auto lg:overflow-y-scroll pbe-12';
const rootPageWrapperClassName = cn(pageWrapperClassName, 'bg-canvas-secondary-light dark:bg-canvas-dark');
const subPageWrapperClassName = cn(pageWrapperClassName, 'bg-canvas-light dark:bg-canvas-secondary-dark');
const subPageNoScrollWrapperClassName =
  'bg-canvas-light dark:bg-canvas-secondary-dark flex justify-center w-full overflow-y-hidden pbe-12';
const dndSubPageWrapperClassName = cn(subPageNoScrollWrapperClassName, 'lg:overflow-y-scroll');
const registerPageWrapperClassName = 'bg-canvas-light dark:bg-canvas-secondary-dark flex flex-col w-full h-full';
const basePageWrapperClassName = 'bg-canvas-secondary-light dark:bg-canvas-dark w-full h-full overflow-y-scroll';
const contentWrapperClassName =
  'w-full h-fit pli-3 md:pli-4 plb-4 grow lg:grow-0 max-w-[1264px] min-h-[calc(100vh-72px)]';

const pageTypeWrappers = {
  [PageType.Root]: rootPageWrapperClassName,
  [PageType.SubPage]: subPageWrapperClassName,
  [PageType.SubPageNoScroll]: subPageNoScrollWrapperClassName,
  [PageType.DnDSubPage]: dndSubPageWrapperClassName,
  [PageType.Register]: registerPageWrapperClassName,
  [PageType.BaseNextPage]: basePageWrapperClassName,
};

const baseRibbonWrapperClassName = 'bg-white dark:bg-canvas-secondary-dark pbs-4 pli-3 md:pli-4';
const ribbonWrapper: Partial<{ [key in PageType]: string }> = {
  [PageType.BaseNextPage]: baseRibbonWrapperClassName,
};

const basePageContentWrapperClassName = 'w-full grow lg:grow-0';
const pageTypeContentWrapper: Partial<{ [key in PageType]: string }> = {
  [PageType.BaseNextPage]: basePageContentWrapperClassName,
};

interface Props {
  children: React.ReactNode;
  disableNav?: boolean;
  disableStatusRibbons?: boolean;
  pageType: PageType;
}

/**
 * The PageWrapper is used to wrap the content of a NextPage. It applies base styling and provides page options.
 *
 * @example
 * Root page.
 * ```tsx
 * import PageWrapper, { PageType } from '@/components/PageWrapper';
 *
 * export const ExampleRootPage: NextPage = () => {
 *   return (
 *     <PageWrapper pageType={PageType.Root}>
 *       <Text>Page Content</Text>
 *     </PageWrapper>
 *   );
 * };
 * ```
 *
 * Sub page without navigation menu and without displaying publish ribbon.
 * ```tsx
 * import PageWrapper, { PageType } from '@/components/PageWrapper';
 *
 * export const ExampleSubPageWithoutNav: NextPage = () => {
 *   return (
 *     <PageWrapper pageType={PageType.SubPage} disableNav={true} disableStatusRibbons={true}>
 *       <Text>Page Content</Text>
 *     </PageWrapper>
 *   );
 * };
 * ```
 */
export function PageWrapper({ children, pageType, disableNav = false, disableStatusRibbons = false }: Props) {
  const navState = useMenuTriggerState({});
  const { churchProfile, contentProfile } = useOrganization();

  if (pageType === PageType.Register) {
    return (
      <div className={pageTypeWrappers[pageType]}>
        <div className='max-w-[1248px] self-center w-full'>{children}</div>
      </div>
    );
  }

  return (
    <div
      className={cn(
        'flex flex-col lg:flex-row w-full h-screen lg:overflow-auto',
        navState.isOpen ? 'overflow-hidden' : 'overflow-auto'
      )}
    >
      {disableNav ? null : <Nav state={navState} />}
      <div className={pageTypeWrappers[pageType]}>
        <div className={pageTypeContentWrapper[pageType] ?? contentWrapperClassName} id='content-wrapper'>
          {!disableStatusRibbons ? (
            <div className={ribbonWrapper[pageType]} id='ribbon-wrapper'>
              {disableStatusRibbons || !churchProfile ? null : <PublishChurchProfileStatusActionRibbon />}
              {disableStatusRibbons || !contentProfile ? null : <ConfirmOrganizationProfileStatusRibbon />}
            </div>
          ) : null}
          {children}
        </div>
      </div>
    </div>
  );
}
