import { MenuTriggerState } from '@react-stately/menu';
import {
  cn,
  SideNav,
  SideNavContent,
  SideNavFooter,
  SideNavGroup,
  SideNavGroupDivider,
  SideNavGroupLabel,
  SideNavHeader,
  SideNavItem,
  SideNavItemIcon,
  SideNavItems,
} from '@youversion/design-system-components';
import ArrowsUpDown from '@youversion/design-system-icons/lib/BorderedArrowsUpDown';
import ArrowsUpDownStroked from '@youversion/design-system-icons/lib/BorderedArrowsUpDownStroked';
import BoxArrowDiagonalRight from '@youversion/design-system-icons/lib/BoxArrowDiagonalRight';
import Chart from '@youversion/design-system-icons/lib/Chart';
import ChartStroked from '@youversion/design-system-icons/lib/ChartStroked';
import CheckmarkBoxStackFilled from '@youversion/design-system-icons/lib/CheckmarkBoxStackFilled';
import CheckmarkBoxStackStroked from '@youversion/design-system-icons/lib/CheckmarkBoxStackStroked';
import PaperAirplane from '@youversion/design-system-icons/lib/DiagonalPaperAirplane';
import PaperAirplaneStroked from '@youversion/design-system-icons/lib/DiagonalPaperAirplaneStroked';
import Folder from '@youversion/design-system-icons/lib/Folder';
import FolderStroked from '@youversion/design-system-icons/lib/FolderStroked';
import Grid4 from '@youversion/design-system-icons/lib/Grid4';
import Grid4Stroked from '@youversion/design-system-icons/lib/Grid4Stroked';
import ImageSingle from '@youversion/design-system-icons/lib/ImageSingle';
import PeopleFilled from '@youversion/design-system-icons/lib/PeopleFilled';
import PeopleStroked from '@youversion/design-system-icons/lib/PeopleStroked';
import Pin from '@youversion/design-system-icons/lib/Pin';
import PinStroked from '@youversion/design-system-icons/lib/PinStroked';
import Sparkle from '@youversion/design-system-icons/lib/Sparkle';
import SparkleStroked from '@youversion/design-system-icons/lib/SparkleStroked';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTheme } from 'next-themes';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Metrics, UserAction } from '@/api/metrics';
import FeatureFlag from '@/components/FeatureFlag/FeatureFlag';
import { PermissionGuard } from '@/components/permissions';
import { useAppLanguage } from '@/context/appLanguage';
import { useOrganization } from '@/context/organization/useOrganization';
import { useCelebrationStatus } from '@/hooks/insights/useCelebrationStatus';
import { useSupportUrls } from '@/hooks/use-support-urls';
import { useGetFeatureFlags } from '@/hooks/useGetFeatureFlags';
import { roleEditPermission } from '@/utils/constants';
import { IS_STAGING } from '@/utils/env';
import { formatHelpLink } from '@/utils/format-help-link/formatHelpLink';

import AccountMenu from '../AccountMenu';
import useAllResourceIds from '../permissions/useAllResourceIds';
import { CelebrationNavItem } from './CelebrationNavItem';
import NavTextLink from './NavTextLink';
import OrganizationPopover from './OrganizationPopover';

interface Props {
  insightsHref?: string;
  state: MenuTriggerState;
}

export function NavItems({ insightsHref, state }: Props) {
  const router = useRouter();
  const { resolvedTheme } = useTheme();
  const { t } = useTranslation(['common']);
  const { organizationId, hasMultipleLocations, organization } = useOrganization();
  const allResourceIds = useAllResourceIds();
  const { feedbackUrl, supportUrl } = useSupportUrls();
  const celebrationStatus = useCelebrationStatus();
  const { appLanguage } = useAppLanguage();
  const helpUrl = formatHelpLink(appLanguage);
  const { hasFeature, isLoading: isLoadingFeatureFlags } = useGetFeatureFlags({ featureFlag: 'scheduled_posts' });

  const isLinkActive = useCallback(
    (href: string) => {
      const basePath = href.split('/')[1];
      const currentPathStart = router?.pathname?.split('/')[1];

      return currentPathStart === basePath;
    },
    [router]
  );

  const planPortalUrl = IS_STAGING
    ? 'https://staging.partnerportal.youversion.com'
    : 'https://partnerportal.youversion.com';

  // TODO: remove when "scheduled_posts" feature is fully rolled out
  const postURL = useMemo(() => {
    if (!isLoadingFeatureFlags && hasFeature) {
      return `/organization/${organizationId}/posts`;
    }
    return hasMultipleLocations ? '/posts' : `/posts/${organizationId}`;
  }, [isLoadingFeatureFlags, hasFeature, hasMultipleLocations, organizationId]);

  return (
    <SideNav className='z-10' state={state}>
      <SideNavHeader className='pli-1'>
        <OrganizationPopover />
      </SideNavHeader>
      <SideNavContent>
        <SideNavGroup>
          <SideNavGroupLabel>{t('common:content')}</SideNavGroupLabel>
          <SideNavItems>
            <PermissionGuard
              permissions={[
                {
                  permissionKey: 'metrics.insights.view',
                  resources: allResourceIds,
                },
              ]}
            >
              <SideNavItem asChild={true} isActive={isLinkActive(insightsHref ?? '/insights')}>
                <Link href={insightsHref ?? '/insights'}>
                  <SideNavItemIcon
                    icon={{
                      filled: <Chart />,
                      outlined: <ChartStroked />,
                    }}
                  />
                  {t('common:insights')}
                </Link>
              </SideNavItem>
            </PermissionGuard>

            {celebrationStatus.isActive ? (
              <PermissionGuard
                permissions={[
                  {
                    permissionKey: 'metrics.insights.view',
                    resources: allResourceIds,
                  },
                ]}
              >
                <SideNavItem asChild={true} className='p-0 mlb-0.5' isActive={isLinkActive('/insights/celebration')}>
                  <Link
                    href='/insights/celebration'
                    onClick={() =>
                      Metrics.sendUserActionStartEvent({ action: UserAction.CelebrationPageNavigationSidebar })
                    }
                  >
                    <CelebrationNavItem />
                  </Link>
                </SideNavItem>
              </PermissionGuard>
            ) : null}

            <FeatureFlag featureFlag='experimental_insights_page'>
              <SideNavItem asChild={true} isActive={isLinkActive('/experimental/youversion')}>
                <Link href='/experimental/youversion'>
                  <SideNavItemIcon
                    icon={{
                      filled: <Sparkle />,
                      outlined: <SparkleStroked />,
                    }}
                  />
                  Experimental
                </Link>
              </SideNavItem>
            </FeatureFlag>
            <PermissionGuard
              permissions={[
                {
                  permissionKey: 'content.relationships.view',
                  resources: [organization?.fqrn],
                },
              ]}
              profiles={['content']}
            >
              <SideNavItem asChild={true} isActive={isLinkActive('/content/plans')}>
                <Link href='/content/plans'>
                  <SideNavItemIcon
                    icon={{
                      filled: <Folder />,
                      outlined: <FolderStroked />,
                    }}
                  />
                  {t('common:your_content')}
                </Link>
              </SideNavItem>
            </PermissionGuard>
            <PermissionGuard
              permissions={[
                {
                  permissionKey: 'organizations.profiles.content.update',
                  resources: [organization?.fqrn],
                },
              ]}
              profiles={['content']}
            >
              <SideNavItem asChild={true} isActive={isLinkActive(planPortalUrl)}>
                <a href={planPortalUrl} rel='noreferrer' target='_blank'>
                  <SideNavItemIcon
                    icon={{
                      filled: <CheckmarkBoxStackFilled />,
                      outlined: <CheckmarkBoxStackStroked />,
                    }}
                  />
                  <span className='w-full'>{t('common:plans_portal')}</span>
                  <BoxArrowDiagonalRight className='mis-auto' opacity={0.5} size='18' />
                </a>
              </SideNavItem>
            </PermissionGuard>
          </SideNavItems>
        </SideNavGroup>
        <SideNavGroupDivider />
        <SideNavGroup>
          <SideNavGroupLabel>{t('common:app_page')}</SideNavGroupLabel>
          <SideNavItems>
            <PermissionGuard
              permissions={[
                {
                  permissionKey: 'content.playlist.update',
                  resources: [organization?.fqrn],
                },
              ]}
              profiles={['content']}
            >
              <SideNavItem asChild={true} isActive={isLinkActive('/order-collections')}>
                <Link href='/order-collections'>
                  <SideNavItemIcon
                    icon={{
                      filled: <ArrowsUpDown />,
                      outlined: <ArrowsUpDownStroked />,
                    }}
                  />
                  {t('common:order_collections')}
                </Link>
              </SideNavItem>
            </PermissionGuard>
            <PermissionGuard
              permissions={[
                {
                  permissionKey: 'content.playlist.update',
                  resources: [organization?.fqrn],
                },
              ]}
              profiles={['content']}
            >
              <SideNavItem asChild={true} isActive={isLinkActive('/plans-collections')}>
                <Link href='/plans-collections'>
                  <SideNavItemIcon
                    icon={{
                      filled: <Grid4 />,
                      outlined: <Grid4Stroked />,
                    }}
                  />
                  {t('common:custom_plans_collections')}
                </Link>
              </SideNavItem>
            </PermissionGuard>
            <PermissionGuard
              permissions={[
                {
                  permissionKey: 'organizations.church.view',
                  resources: [organization?.fqrn],
                },
              ]}
              profiles={['church', 'content']}
            >
              <SideNavItem asChild={true} isActive={isLinkActive('/featured-plan')}>
                <Link href='/featured-plan'>
                  <SideNavItemIcon
                    icon={{
                      filled: <Pin />,
                      outlined: <PinStroked />,
                    }}
                  />
                  {t('common:featured_plan')}
                </Link>
              </SideNavItem>
            </PermissionGuard>
            <PermissionGuard
              permissions={[
                {
                  permissionKey: 'organizations.posts.create',
                  resources: allResourceIds,
                },
                {
                  permissionKey: 'organizations.posts.update',
                  resources: allResourceIds,
                },
              ]}
              profiles={['church']}
            >
              <SideNavItem asChild={true} isActive={isLinkActive(postURL)}>
                <Link href={postURL}>
                  <SideNavItemIcon
                    icon={{
                      filled: <PaperAirplane />,
                      outlined: <PaperAirplaneStroked />,
                    }}
                  />
                  {t('common:links.posts')}
                </Link>
              </SideNavItem>
            </PermissionGuard>
            <PermissionGuard
              permissions={[
                {
                  permissionKey: 'organizations.church.view',
                  resources: [organization?.fqrn],
                },
              ]}
              profiles={['church', 'content', 'bible']}
            >
              <SideNavItem asChild={true} isActive={isLinkActive('/profile/information')}>
                <Link href='/profile/information'>
                  <div className='w-4 h-3 overflow-hidden rounded-full'>
                    {organization?.logo?.imageUrl ? (
                      <Image
                        alt={t('common:organization_logo')}
                        className='h-full w-full'
                        height={32}
                        src={organization.logo.imageUrl}
                        width={32}
                      />
                    ) : (
                      <ImageSingle height={24} width={24} />
                    )}
                  </div>
                  <span className='w-full truncate'>{t('common:page_info')}</span>
                  <div>
                    {organization?.backgroundColor ? (
                      <div className={cn(`bg-[${organization?.backgroundColor}] h-1 w-1 rounded-full`)} />
                    ) : null}
                  </div>
                </Link>
              </SideNavItem>
            </PermissionGuard>
          </SideNavItems>
        </SideNavGroup>
        <PermissionGuard
          permissions={[
            {
              permissionKey: roleEditPermission,
              resources: [organization?.fqrn],
            },
          ]}
        >
          <SideNavGroupDivider />
          <SideNavGroup>
            <SideNavGroupLabel>{t('common:manage')}</SideNavGroupLabel>
            <SideNavItems>
              <SideNavItem asChild={true} isActive={isLinkActive('/accounts')}>
                <Link href='/accounts'>
                  <SideNavItemIcon
                    icon={{
                      filled: <PeopleFilled />,
                      outlined: <PeopleStroked />,
                    }}
                  />
                  {t('common:accounts')}
                </Link>
              </SideNavItem>
            </SideNavItems>
          </SideNavGroup>
        </PermissionGuard>
      </SideNavContent>
      <SideNavFooter className='flex flex-col items-center relative mbe-4 mbs-3 gap-3 pli-1'>
        <AccountMenu popoverAlign={{ horizontal: 'start', vertical: 'top' }} />
        <div className='flex flex-col items-center gap-1'>
          <NavTextLink href={helpUrl} target='_blank'>
            {t('common:help')}
          </NavTextLink>
          <NavTextLink href={supportUrl} target='_blank'>
            {t('common:support')}
          </NavTextLink>
          <NavTextLink href={feedbackUrl} target='_blank'>
            {t('common:share_feedback')}
          </NavTextLink>
        </div>
        <Image
          alt={t('common:yv_connect')}
          height={24}
          src={`/svg/youversion-connect-${resolvedTheme}.svg`}
          width={168}
        />
      </SideNavFooter>
    </SideNav>
  );
}
