import * as React from 'react';

/**
 * A simple component to display an outlined circle with a thickness, inside a padded container.
 *
 * @example
 * ```tsx
 * import { EmptyCircleIcon } from '@/components';
 *
 * function MyComponent() {
 *   return (
 *     <EmptyCircleIcon color="#e80033" />
 *   )
 * }
 * ```
 */
export const EmptyCircleIcon = ({
  color = '#D0D2D2',
  size = '40px',
  padding = '7px',
  borderWidth = '2px',
  borderStyle = 'solid',
}) => {
  return (
    <div style={{ color, height: size, padding, width: size }}>
      <div
        style={{
          borderRadius: '50%',
          borderStyle,
          borderWidth,
          height: '100%',
          width: '100%',
        }}
      ></div>
    </div>
  );
};
