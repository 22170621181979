import { Text } from '@youversion/design-system-components';
import ArrowRightStroked from '@youversion/design-system-icons/lib/ArrowRightStroked';
import { useTranslation } from 'react-i18next';

export function CelebrationNavItem() {
  const { t } = useTranslation(['common']);

  return (
    <div className='w-full flex justify-between items-center bg-blue-10 dark:bg-blue-50 p-2 rounded-0.5'>
      <Text className='text-blue-40 dark:text-blue-20'>{t('common:celebration.nav_button')}</Text>
      <ArrowRightStroked className='text-blue-40 dark:text-blue-20' height={16} width={16} />
    </div>
  );
}
