import { cn, Text } from '@youversion/design-system-components';
import ChevronLeftStroked from '@youversion/design-system-icons/lib/ChevronLeftStroked';
import ChevronRightStroked from '@youversion/design-system-icons/lib/ChevronRightStroked';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const backgroundColorVariants = {
  alert: `bg-alert dark:bg-yv-red-dark`,
  caution: `bg-caution dark:bg-caution`,
  info: `bg-info dark:bg-info-dark`,
  success: `bg-success dark:bg-green-40`,
} as const;

type BackgroundColorStyle = keyof typeof backgroundColorVariants;

/**
 * A styled well that displays a title (meant to serve as the name of action to take) and description. Displays
 * a chevron icon if an onClick action is provided. This notifies the user that the well is intractable.
 */
interface Props {
  ariaLabel?: string;
  background: BackgroundColorStyle | undefined;
  description: string;
  onClick?: () => void;
  title: string;
}
function TakeActionWell({ title, description, onClick, background, ariaLabel }: Props) {
  const [isHovering, setIsHovering] = useState(false);
  const isClickable = Boolean(onClick !== undefined);
  const { i18n } = useTranslation();
  // @ts-expect-error: Property 'dir' does not exist on type 'i18n'
  const isRtl = i18n.dir() === 'rtl';

  const className = useMemo(
    () =>
      cn(
        `p-2 mbe-4 items-baseline rounded-1 text-white shadow-light w-full text-start`,
        isClickable ? 'cursor-pointer' : 'cursor-auto',
        backgroundColorVariants[background || 'info']
      ),
    [background, isClickable]
  );

  const chevronIcon = isRtl ? (
    <ChevronLeftStroked className='self-center' data-testid='Chevron Left Icon' opacity={isHovering ? '1' : '0.5'} />
  ) : (
    <ChevronRightStroked className='self-center' data-testid='Chevron Right Icon' opacity={isHovering ? '1' : '0.5'} />
  );

  return onClick ? (
    <button
      aria-label={ariaLabel}
      className={className}
      onClick={onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className='flex min-w-full'>
        <div className='min-w-min flex-1'>
          <Text className='pbe-1 text-white'>{title}</Text>
          <Text className='opacity-70 text-13 text-white'>{description}</Text>
        </div>
        <div className='flex min-h-[48px]'>{chevronIcon}</div>
      </div>
    </button>
  ) : (
    <div
      aria-label={ariaLabel}
      className={className}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className='flex min-w-full'>
        <div className='min-w-min flex-1'>
          <Text className='pbe-1 text-white'>{title}</Text>
          <Text className='opacity-70 text-13 text-white'>{description}</Text>
        </div>
      </div>
    </div>
  );
}
export default TakeActionWell;
