import { cn, Text } from '@youversion/design-system-components';
import TriangleDownStroked from '@youversion/design-system-icons/lib/TriangleDownStroked';
import { forwardRef, MouseEventHandler, ReactNode } from 'react';

interface Props {
  /** The aria-label for the component wrapper. */
  label: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /** Element that is rendered as the user's avatar. */
  userAvatar: ReactNode;
  /** Name of the user that will be displayed. */
  userName: string;
}

export const UserProfileButton = forwardRef<HTMLButtonElement, Props>(
  ({ userAvatar, userName, label, onClick }, ref) => {
    return (
      <button
        aria-label={label}
        className={cn(
          'flex items-center max-w-[200px] p-[6px] rounded-4 gap-1 border-standard border-tertiary-light dark:border-tertiary-dark'
        )}
        onClick={onClick}
        ref={ref}
      >
        <div className='w-3 h-3 shrink-0'>{userAvatar}</div>
        <Text className='font-bold truncate' size='sm' variant='label'>
          {userName}
        </Text>
        <TriangleDownStroked className='shrink-0' />
      </button>
    );
  }
);

UserProfileButton.displayName = 'UserProfileButton';
