import { Text } from '@youversion/design-system-components';
import * as React from 'react';

interface Props {
  children: React.ReactNode;
  href: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
}

/**
 * Component intended for use in Nav. Does not wrap the text with next/link because external urls are expected.
 * To use an internal url and wrap with next/link, <Text> will need be updated to use React.forwardRef.
 *
 * @example
 * ```tsx
 * import { NavTextLink } from '@/components';
 *
 * function NavBar() {
 *   return (
 *     <>
 *       <NavTextLink href="https://example.com/one">Link One</NavTextLink>
 *       <NavTextLink href="https://example.com/two">Link Two</NavTextLink>
 *     </>
 *   )
 * }
 * ```
 */
export default function NavTextLink({ children, href, target }: Props) {
  return (
    <Text
      className='text-text-muted-light dark:text-text-muted-dark'
      component='a'
      href={href}
      size='xs'
      target={target}
      variant='label'
    >
      {children}
    </Text>
  );
}
