/**
 * Returns the correctly formatted link to partner-support.youversion.com.
 *
 * @param appLanguage - The array of objects to filter.
 * @param suffix - Optional property to determine support page navigation.
 * @returns The formatted help link.
 * @example
 * ```ts
 *  const helpLink = formatHelpLink('en-US', 'xjke04Ye')
 * ```
 */

export function formatHelpLink(appLanguage: string, suffix?: string) {
  const baseUrl = `https://partner-support.youversion.com/l/${appLanguage.slice(0, 2)}`;
  const link = suffix ? `${baseUrl}/${suffix}` : baseUrl;
  return link;
}
