import { useMenuTriggerState } from '@react-stately/menu';
import { useAuth } from '@youversion/auth';
import {
  cn,
  List,
  ListItem,
  Popover,
  PopoverAlign,
  PopoverBody,
  PopoverTrigger,
} from '@youversion/design-system-components';
import { stringReplacer } from '@youversion/utils';
import { useRouter } from 'next/compat/router';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { UserProfileButton } from '@/components/AccountMenu/UserProfileButton';
import { useCurrentOrganizationId } from '@/context/organization/useCurrentOrganizationId';
import { IS_STAGING } from '@/utils/env';
import { safeString } from '@/utils/strings';

import FallbackAvatar from './FallbackAvatar';
import SettingsDialog from './SettingsDialog';

interface Props {
  popoverAlign?: PopoverAlign;
}

const IMAGE_SIZE = 24;

function AccountMenu({ popoverAlign }: Props) {
  const popoverState = useMenuTriggerState({});
  const settingsState = useMenuTriggerState({});
  const router = useRouter();
  const { nukeFromOrbit } = useCurrentOrganizationId();
  const { signOut } = useAuth();
  const { t } = useTranslation('common');
  const { user } = useAuth();
  const [userAvatarError, setUserAvatarError] = React.useState(false);
  const resolvedName = safeString(user?.firstName || user?.lastName || user?.username || user?.email?.split('@')[0]);

  const handleOpenSettings = () => {
    settingsState.open();
    popoverState.close();
  };

  const handleSignOut = () => {
    fetch('/api/cookies', {
      body: JSON.stringify({ name: 'ssrYva' }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }).then(() => {
      nukeFromOrbit();
      router?.replace('/');
      signOut();
    });
  };

  const userAvatar = React.useMemo(() => {
    return userAvatarError ? (
      <FallbackAvatar name={resolvedName} />
    ) : (
      <Image
        alt={resolvedName}
        className='rounded-full h-full w-full object-fit'
        height={IMAGE_SIZE}
        onError={() => setUserAvatarError(true)}
        // avatarUrl comes without a protocol, so we're safely adding a protocol with a URL constructor so Next doesn't crash. See https://github.com/vercel/next.js/issues/18536.
        src={
          new URL(
            user?.avatarUrl ? stringReplacer(user.avatarUrl, { height: IMAGE_SIZE, width: IMAGE_SIZE }) : '',
            'https://example.com'
          ).href
        }
        width={IMAGE_SIZE}
      />
    );
  }, [resolvedName, user?.avatarUrl, userAvatarError]);

  return (
    <Popover>
      {user ? (
        <PopoverTrigger onToggle={popoverState.toggle}>
          <UserProfileButton label={t('account_menu')} userAvatar={userAvatar} userName={safeString(user.firstName)} />
        </PopoverTrigger>
      ) : null}

      {popoverState.isOpen ? (
        <PopoverBody
          align={popoverAlign}
          className={cn('min-h-0 w-[200px] dark:bg-gray-45')}
          isDismissible={true}
          isOpen={true}
          onClose={popoverState.close}
        >
          <List>
            {user ? (
              <ListItem>
                <a
                  href={`https://login${IS_STAGING ? '-staging' : ''}.youversion.com/profile`}
                  rel='noreferrer'
                  target='_blank'
                >
                  {t('my_profile')}
                </a>
              </ListItem>
            ) : null}

            <ListItem className='text-info-light' divider={true} onClick={handleOpenSettings}>
              {t('settings')}
            </ListItem>
            <ListItem className='text-info-light' onClick={handleSignOut}>
              {t('log_out')}
            </ListItem>
          </List>
        </PopoverBody>
      ) : null}
      {settingsState.isOpen ? <SettingsDialog onClose={settingsState.close} /> : null}
    </Popover>
  );
}

export default AccountMenu;
